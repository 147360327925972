import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterItem'
})
export class FilterItemPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let items;
    let res: any = [];
    if(args[0]){
      let search_text = args[0];
      let catItems = Object.values(value);
      catItems.forEach(function(catItem: any) {
        catItem.value.forEach(function(item: any) {
          if(item.name.toLowerCase().indexOf(search_text) > -1){
            var innerObj = [];
            //innerObj[catItem.key] = item;
            res.push(item)
          }
        });
      });
      items  = res;
    }else{
       items = value;
    }
    return items
  }

}
