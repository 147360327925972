import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExternalLibraryService} from '../util';
import {HttpClient, HttpParams} from "@angular/common/http";
import {CreditCardValidators} from "angular-cc-library";
import {environment} from "../../environments/environment";

declare var Razorpay: any;

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

    cardSection: boolean = false;
    totalPrice: any;
    totalAmtPaisa: any;
    errorMsg: any;
    RAZORPAY_OPTIONS: any;
    pageLoader: any;
    createPaymentData: any;
    googlePayId: any;
    paymentForm: any = FormGroup;
    googleUPI: boolean = false;
    googleMobilePay: boolean = false;
    orderDetails: any;
    restaurantDetails: any;
    orderId: any
    data: any
    paytm: any
    paymentMethods: any
    params: any


    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private razorpayService: ExternalLibraryService,
        private httpClient: HttpClient,
        private fb: FormBuilder
    ) {
    }

    ngOnInit(): void {

        this.orderId = this.activeRoute.snapshot.paramMap.get("id");
        let items = JSON.parse(localStorage.getItem('cart') || '{}');
        this.restaurantDetails = JSON.parse(localStorage.getItem('restaurantDetails') || '{}');
        this.params = JSON.parse(localStorage.getItem('query_params') || '{}');
        if ((!items.length && this.restaurantDetails.token)  || !this.orderId) {
            this.router.navigate(['/restaurant', this.restaurantDetails.token], { queryParams: this.params});
        }
        this.paymentMethods = this.restaurantDetails.meta.payment_modes;
        this.getOrder(this.orderId);
       // this.getOrderId()
        // payment service

        this.RAZORPAY_OPTIONS = {
            key: environment.ROZARPAY_KEY
        };



        this.paymentForm = this.fb.group({
            card_number: ['', [Validators.required, CreditCardValidators.validateCCNumber]],
            name_of_card: ['', Validators.required],
            expDate: ['', [Validators.required, CreditCardValidators.validateExpDate]],
            cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
        });
        this.razorpayService
            .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
            .subscribe();

        this.razorpayService
            .lazyLoadLibrary('https://checkout.razorpay.com/v1/razorpay.js')
            .subscribe();


        this.paytm = {
            MID: "oXOTvC65310135716653", // paytm provide
            WEBSITE: "WEBSTAGING", // paytm provide
            INDUSTRY_TYPE_ID: "Retail", // paytm provide
            CHANNEL_ID: "WEB", // paytm provide
            ORDER_ID: "order_GE76ncPVUiyWFC", // unique id
            CUST_ID: "12", // customer id
            MOBILE_NO: "9136230513", // customer mobile number
            EMAIL: "vikas@signifier.in", // customer email
            TXN_AMOUNT: "10.00", // transaction amount
            CALLBACK_URL: "https://restaurants.rannkly.test/checksum", // Call back URL that i want to redirect after payment fail or success
        };
    }

    getOrderId() {
        this.httpClient.post('https://api.razorpay.com/v1/orders', {
            "amount": this.totalAmtPaisa,
            "currency": "INR",
            "receipt": Math.random().toString(36).substring(7)
        }).subscribe(data => {
                console.log(data);
            },
            err => {
                console.log(err);
            },
            () => console.log('done order id')
        );
    }

    setGooglePayId(event: any) {
        this.googlePayId = event.target.value;
    }

    googlePay() {
        this.pageLoader = true;
        this.cardSection = false;
        const razorpay = new Razorpay(this.RAZORPAY_OPTIONS);
        let that = this;

        if (this.isMobileDevice()) {
            razorpay.checkPaymentAdapter('gpay')
                .then(() => {
                    razorpay.createPayment(this.createPaymentData, {gpay: true});
                    razorpay.on('payment.success', function (response: any) {
                        // response.razorpay_payment_id
                        // response.razorpay_order_id
                    }).on('payment.error', function (error: any) {
                        that.pageLoader = false;
                        that.errorMsg = error.description;
                    })

                })
                .catch((error: any) => {
                    this.pageLoader = false;
                    this.errorMsg = error.description;
                });

        } else {

            if (this.googlePayId) {
                this.createPaymentData.method = 'upi';
                this.createPaymentData.upi = {vpa: this.googlePayId, flow: 'collect'};
                razorpay.verifyVpa(this.googlePayId)
                    .then(() => {
                        this.submitPayment();
                    })
                    .catch((error: any) => {
                        this.errorMsg = error.error.description;
                    });
            } else {
                this.setPaymentSec('googlePay');
                this.pageLoader = false;
            }

        }


    }

    walletPayment(walletType: any) {

        this.setPaymentSec(walletType)
        this.pageLoader = true;
        this.createPaymentData.method = 'wallet';
        this.createPaymentData.wallet = walletType;
        this.submitPayment();
    }

    cardPayment() {
        this.pageLoader = true;
        const paymentFormData = this.paymentForm.value;
        this.createPaymentData.method = 'card';
        const expiryDateArray = paymentFormData.expDate.split('/');
        this.createPaymentData.card = {
            name: paymentFormData.name_of_card,
            expiry_month: expiryDateArray[0].trim(),
            expiry_year: expiryDateArray[1].trim(),
            number: paymentFormData.card_number,
            cvv: paymentFormData.cvv
        };
        this.submitPayment();
        return true
    }

    public submitPayment() {

        let createOrderIDUrl = environment.API_ENDPOINT + 'order/create';
        this.httpClient.post(createOrderIDUrl, {id: this.orderId, payment_type: 'online'}).subscribe(data => {
                this.data = data;
                if (this.data.success) {
                    this.createPaymentData.order_id =  this.data.order_id

                    const razorpay = new Razorpay(this.RAZORPAY_OPTIONS);
                    if (this.googleMobilePay) {
                        razorpay.createPayment(this.createPaymentData, {gpay: true});
                    } else {
                        razorpay.createPayment(this.createPaymentData);
                    }

                    razorpay.on('payment.success', (response: any) => {
                        // console.log(response);
                        //  this.router.navigate(['/thanks']);

                        this.verifySignature(response);
                    });
                    razorpay.on('payment.error', (response: any) => {
                        this.errorMsg = response.error.description;
                        this.pageLoader = false;
                    });
                }
            },
            err => {
                this.errorMsg = err.message;
            },
            () => {
               // this.pageLoader = false;
            }
        );


    }

    cashPayment(){
        this.pageLoader = true;
        let createOrderIDUrl = environment.API_ENDPOINT + 'order/create';
        this.httpClient.post(createOrderIDUrl, {id: this.orderId, payment_type: 'cash'}).subscribe(data => {
                this.data = data;
                if (this.data.success) {
                    this.createPaymentData.order_id =  this.data.order_id
                    this.router.navigate(['/thanks']);
                }
            },
            err => {
                this.errorMsg = err.message;
            },
            () => {
                 this.pageLoader = false;
            }
        );
    }

    paytmPayment(){

       this.paytm.CHECKSUMHASH = "48WcMR56DV9H6e3QlJzTAotIIu2BHx8gZ+A52I1w2qvk1gUlkpOGQkV/P9BYEEK+yp1Z4/RZykqKut9WsNo+d1bibcc2XEo545u7jLasHOI="
        this.createPaytmForm();

    }

    createPaytmForm() {
        const my_form: any = document.createElement('form');
        my_form.name = 'paytm_form';
        my_form.method = 'post';
        my_form.action = 'https://securegw-stage.paytm.in/order/process';


        const myParams = Object.keys(this.paytm);
        for (let i = 0; i < myParams.length; i++) {
            const key = myParams[i];
            let my_tb: any = document.createElement('input');
            my_tb.type = 'hidden';
            my_tb.name = key;
            my_tb.value = this.paytm[key];
            my_form.appendChild(my_tb);
        };

        document.body.appendChild(my_form);
        my_form.submit();
// after click will fire you will redirect to paytm payment page.
// after complete or fail transaction you will redirect to your CALLBACK URL
    };

    verifySignature(res: any) {
        let verifySignatureUrl = environment.API_ENDPOINT + 'verify/signature';
        this.httpClient.post(verifySignatureUrl, res).subscribe(data => {
                this.data = data;
                if (this.data.status) {
                    this.router.navigate(['/thanks']);
                }
            },
            err => {
                this.errorMsg = err.message;
            },
            () => {
                this.pageLoader = false;
            }
        );
    }

    public goToNextField(controlName: string, nextField: HTMLInputElement) {
        //  const contrl = this.paymentForm.get(controlName);
        if (this.paymentForm.get(controlName)?.valid) {
            nextField.focus();
        }
    }

    public isMobileDevice() {
        return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
    }

    cardPaymentSec() {
        this.pageLoader = false;
        this.setPaymentSec('card');
    }

    setPaymentSec(current: any) {
        this.cardSection = false;
        this.googleUPI = false;
        this.errorMsg = false;
        if (current == 'card') {
            this.cardSection = true
        } else if (current == 'googlePay') {
            this.googleUPI = true;
        } else {

        }
    }

    getOrder(order_id: any){
        let orderUrl = environment.API_ENDPOINT + 'order/'+order_id;
        this.httpClient.get(orderUrl).subscribe(data => {
                this.orderDetails = data;
               // console.log(this.orderDetails.details.total_price)
                if (this.orderDetails.success) {
                    if(this.orderDetails.details.payment_status){
                        this.router.navigate(['/restaurant', this.restaurantDetails.token], { queryParams: this.params});
                    }
                    this.totalPrice = this.orderDetails.details.total_price;
                    this.totalAmtPaisa = this.totalPrice * 100;
                    this.createPaymentData = {
                        amount: this.totalAmtPaisa,
                        currency: 'INR',
                        contact: this.orderDetails.details.customer.mobile_number,
                        email: this.orderDetails.details.customer.email
                    };
                }else {
                    this.router.navigate(['/restaurant', this.restaurantDetails.token], { queryParams: this.params});
                }
            },
            err => {
                // this.errorMsg = err.message;
            },
            () =>{
                // complete
            }
        );
    }

}
