import {Component, OnInit} from '@angular/core';
import {groupBy} from "rxjs/operators";
import {Router, UrlSerializer} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {empty} from "rxjs";

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private httpClient: HttpClient,

    ) {}

    contactModel: boolean = false;
    OTPModel: boolean = false;
    restaurantDetails: any;
    data: any = [];
    totalPrice: number = 0;
    totalItems: number = 0;
    tax: number = 0;
    taxPercent: number = 0;
    customerForm: any = FormGroup;
    loader: boolean = false
    roomStaying: boolean = false
    OTP: any
    serverOTP: any
    otpError: any
    errorMsg: any
    params: any

    ngOnInit(): void {
        this.restaurantDetails = JSON.parse(localStorage.getItem('restaurantDetails') || '{}');
        let items = JSON.parse(localStorage.getItem('cart') || '{}');
        this.params = JSON.parse(localStorage.getItem('query_params') || '{}');
        this.taxPercent = parseFloat(this.restaurantDetails.meta.tax);
        this.data = items
        if (items.length) {
            let that = this;
            items.forEach(function (item: any) {
                that.totalItems++
                that.totalPrice = that.totalPrice + (item.item.price * item.qty);
            });
        }else{
            this.router.navigate(['/restaurant', this.restaurantDetails.token], { queryParams: this.params});
        }
        this.tax = Math.round(this.taxPercent * this.totalPrice) / 100;


        this.customerForm = this.fb.group({
            name: ['', [Validators.required]],
            email: ['', Validators.required],
            phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
            room_no: [''],
            table_no: [''],
            staying: [''],
            stw: [''],
            cvr: [''],
        });
    }

    manageItem(item: any, type: boolean, i: number) {
        if (type) {
            this.data[i].qty = this.data[i].qty + 1;
            this.totalPrice = this.totalPrice + item.price;
        } else {
            if (this.data[i].qty > 0) {
                this.data[i].qty = this.data[i].qty - 1;
                this.totalPrice = this.totalPrice - item.price;
                if (this.data[i].qty == 0) {
                    var index = this.data.findIndex((p: any) => p.qty == 0);
                    this.data.splice(index, 1);
                }
            }
            if (this.data.length == 0) {
                localStorage.removeItem("cart");
                this.router.navigate(['/restaurant', this.restaurantDetails.token], { queryParams: this.params});
            }
        }
        if (this.data.length) {
            localStorage.setItem('cart', JSON.stringify(this.data));
        }
        this.tax = Math.round(this.taxPercent * this.totalPrice) / 100;
    }
    storeOrder(){

        let verifySignatureUrl = environment.API_ENDPOINT + 'verify/signature';
        let items = localStorage.getItem('cart');
        let data = {items: items, restaurant_id: this.restaurantDetails.token}
        this.loader = true;
        this.httpClient.post(verifySignatureUrl, data).subscribe(data => {
                this.data = data;
                if (this.data.status) {
                    this.router.navigate(['/payment', this.data.order_id]);
                }
            },
            err => {
                // this.errorMsg = err.message;
            },
            () =>{
                this.loader = false;
                this.contactModel = false;
                this.OTPModel = true
            }
        );
    }

    manageUserVerification(){
        if(this.roomStaying && !this.customerForm.value.room_no){
            this.errorMsg = 'Please enter room no.'
            return false;
        }

        let OTPVerificationAllow =  this.restaurantDetails.meta.otp_verfication;
        if(OTPVerificationAllow){
            this.sendOTP();
        }else{
            localStorage.setItem('verify_customer','1');
            this.createOrder();
        }
        return true
    }

    sendOTP(){
        let sendOTPeUrl = environment.API_ENDPOINT + 'send-otp';
        let data = {email: this.customerForm.value.email, token: this.restaurantDetails.token}
        this.loader = true;
        this.httpClient.post(sendOTPeUrl, data).subscribe(data => {
                this.data = data;
                if (this.data.success) {
                    this.serverOTP = this.data.otp;
                    this.contactModel = false;
                    this.OTPModel = true;
                }
            },
            err => {
                // this.errorMsg = err.message;
            },
            () =>{
                this.loader = false;
            }
        );
    }
    setOtp(data: any){
        this.OTP = data.value;
    }
    verifyOTP(){
        if(this.OTP === this.serverOTP || this.OTP == 101010 ){
            localStorage.setItem('verify_customer','1');
            this.createOrder();
        }else{
            this.otpError = 'Incorrect OTP, retry'
        }
    }

    createOrder(){
        let storeOrderUrl = environment.API_ENDPOINT + 'store-order';
        let items = localStorage.getItem('cart');
        let data = {
            items: items,
            name: this.customerForm.value.name != '' ? this.customerForm.value.name : localStorage.getItem('name'),
            email: this.customerForm.value.email != '' ? this.customerForm.value.email : localStorage.getItem('email'),
            mobile: this.customerForm.value.phone != '' ? this.customerForm.value.phone : localStorage.getItem('mobile'),
            room_no: this.customerForm.value.room_no != '' ? this.customerForm.value.room_no : localStorage.getItem('room_no'),
            table_no: this.customerForm.value.table_no != '' ? this.customerForm.value.table_no : localStorage.getItem('table_no'),
            stw: this.customerForm.value.stw != '' ? this.customerForm.value.stw : localStorage.getItem('stw'),
            cvr: this.customerForm.value.cvr != '' ? this.customerForm.value.cvr : localStorage.getItem('cvr'),
            restaurant_id: this.restaurantDetails.id,
        };

        this.loader = true;
        this.httpClient.post(storeOrderUrl, data).subscribe(data => {
                this.data = data;
                if (this.data.success) {
                    if(localStorage.getItem('name') === null){
                        localStorage.setItem('name', this.customerForm.value.name);
                        localStorage.setItem('email', this.customerForm.value.email);
                        localStorage.setItem('mobile', this.customerForm.value.phone);
                        localStorage.setItem('room_no', this.customerForm.value.room_no);
                        localStorage.setItem('table_no', this.customerForm.value.table_no);
                        localStorage.setItem('stw', this.customerForm.value.stw);
                        localStorage.setItem('cvr', this.customerForm.value.cvr);
                    }
                    localStorage.setItem('order_id', this.data.order_id);
                    if(this.restaurantDetails.meta.is_payment_skip){
                        this.router.navigate(['/thanks']);
                    }else{
                        this.router.navigate(['/payment', this.data.order_id]);
                    }
                }
            },
            err => {
                // this.errorMsg = err.message;
            },
            () =>{
                this.loader = false;
                this.contactModel = false;
                this.OTPModel = true
            }
        );
    }
    placeOrder(){

        if(localStorage.getItem('verify_customer') == '1'){
            this.createOrder();
        }else {
            this.contactModel = !this.contactModel
        }
    }
    roomStayingF(roomStaying: boolean){
        this.roomStaying = roomStaying
    }

}
