import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RestaurantComponent} from './restaurant.component';
import {RestaurantRoutingModule} from "./restaurant-routing.module";
import {HttpClientModule} from "@angular/common/http";
import {FilterItemPipe} from "../pipes/filter-item.pipe";

@NgModule({
  declarations: [RestaurantComponent, FilterItemPipe],
  imports: [
    CommonModule,
    RestaurantRoutingModule,
    HttpClientModule,
  ],
  exports: [
    FilterItemPipe
  ],
  providers: []
})
export class RestaurantModule { }
