<div class="flexbox payment-loader" *ngIf="pageLoader">
    <div>
        <div class="dbl-spinner"></div>
        <div class="dbl-spinner dbl-spinner--2"></div>
    </div>
</div>
<div class="resturant-page">
    <div class="menu-top-sec">
        <div class="menu-all-sec summary-page">
            <div class="menu-listing-sec">
                <a [routerLink]="['/order']" class="back-btn">
                    <img src="../../assets/images/back-icon-1.png" class="back-icon" alt="back-icon">
                </a>
                <h4 class="menu-top-heading">Select payment option</h4>
                <div class="payment-gateway-top">
                    <p class="heading-16 f-w-n">Amount payable</p>
                    <h4 class="heading-20">₹ {{totalPrice}}.00</h4>
                </div>
            </div>
            <div class="error-sec" *ngIf="errorMsg">
                <img src="../../assets/images/error.png" alt="error" class="error-img">
                <p class="error-txt">{{errorMsg}}</p>
            </div>
            <div class="payment-gateway-option">
                <div class="gateway-option-item" *ngIf="paymentMethods.google_pay">
                    <img src="../../assets/images/google.png" alt="" class="gateway-item-img google-logo">
                    <button class="pay-btn heading-16" (click)="googlePay()">Pay now</button>
                </div>
                <!--<div class="payment-input-group" *ngIf="googleUPI && paymentMethods.google_pay">
                    <div class="google-pay">
                        <input  type="text" class="payment-input" placeholder="Enter Google UPI Id" (keyup)="setGooglePayId($event)">
                    </div>
                    <button (click)="googlePay()"  class="menu-btn-primary heading-16 google-pay-validate" [disabled]="!googlePayId">Validate UPI</button>
                </div>-->
                <div class="gateway-option-item" *ngIf="paymentMethods.paytm">
                    <img src="../../assets/images/paytm.png" alt="" class="gateway-item-img paytm-logo">
                    <button class="pay-btn heading-16" (click)="walletPayment('paytm')">Pay now</button>
                </div>
                <div class="gateway-option-item" *ngIf="paymentMethods.amazon_pay">
                    <img src="../../assets/images/amazon.png" alt="" class="gateway-item-img amazon-logo">
                    <button class="pay-btn heading-16" (click)="walletPayment('amazonpay')">Pay now</button>
                </div>
                <div class="gateway-option-item" *ngIf="paymentMethods.phone_pay">
                    <img src="../../assets/images/phone_pe.png" alt="" class="gateway-item-img amazon-logo">
                    <button class="pay-btn heading-16" (click)="walletPayment('phonepe')">Pay now</button>
                </div>
                <div class="gateway-option-item" *ngIf="paymentMethods.airtel_payment_bank">
                    <img src="../../assets/images/airtel_payments_bank.png" alt="" class="gateway-item-img airtel-logo">
                    <button class="pay-btn heading-16" (click)="walletPayment('airtelmoney')">Pay now</button>
                </div>
                <div class="gateway-option-item" *ngIf="paymentMethods.freecharge">
                    <img src="../../assets/images/fc_logo.png" alt="" class="gateway-item-img amazon-logo">
                    <button class="pay-btn heading-16" (click)="walletPayment('freecharge')">Pay now</button>
                </div>
                <div class="gateway-option-item" *ngIf="paymentMethods.jio_money">
                    <img src="../../assets/images/jiomoney.png" alt="" class="gateway-item-img jio-logo">
                    <button class="pay-btn heading-16" (click)="walletPayment('jiomoney')">Pay now</button>
                </div>
                <div class="gateway-option-item" *ngIf="paymentMethods.ola_money">
                    <img src="../../assets/images/ola.png" alt="" class="gateway-item-img jio-logo">
                    <button class="pay-btn heading-16" (click)="walletPayment('olamoney')">Pay now</button>
                </div>
                <div class="gateway-option-item" *ngIf="paymentMethods.payzapp">
                    <img src="../../assets/images/payzapp.png" alt="" class="gateway-item-img jio-logo">
                    <button class="pay-btn heading-16" (click)="walletPayment('payzapp')">Pay now</button>
                </div>
                <div class="gateway-option-item" *ngIf="paymentMethods.mobikwik">
                    <img src="../../assets/images/mobikwik.png" alt="" class="gateway-item-img jio-logo">
                    <button class="pay-btn heading-16" (click)="walletPayment('mobikwik')">Pay now</button>
                </div>
                <div class="gateway-option-item" *ngIf="paymentMethods.cash">
                    <p class="heading-16 f-w-n">CASH</p>
                    <button class="pay-btn heading-16" (click)="cashPayment()">Pay now</button>
                </div>
                <div class="gateway-option-item" *ngIf="paymentMethods.credit_debit_card">
                    <p class="heading-16 f-w-n">Credit/Debit card</p>
                    <button class="pay-btn heading-16" (click)="cardPaymentSec()">Pay now</button>
                </div>
            </div>

            <div *ngIf="cardSection" class="menu-all-sec summary-page">
                <div class="menu-listing-sec">
                    <p class="heading-16 f-w-n payment-subtitle">Enter your payment details to proceed further</p>
                        <form class="payment-form" [formGroup]="paymentForm" (ngSubmit)="cardPayment()">

                        <div class="payment-input-group">
                            <input
                                    (input)="goToNextField('card_number', expireInput)"
                                    type="tel"
                                    id="cc-number"
                                    formControlName="card_number"
                                    class="payment-input {{!paymentForm.controls.card_number.valid && paymentForm.controls.card_number.dirty ? 'invalid' : 'success'}}"
                                    ccNumber
                                    placeholder="Enter your card details"
                            >
                        </div>

                        <div class="payment-multi-group">
                            <div class="payment-input-group">
                                <input
                                        id="cc-exp"
                                        (input)="goToNextField('expDate', cvcInput)"
                                        #expireInput
                                        formControlName="expDate"
                                        type="tel"
                                        autocomplete="cc-exp"
                                        placeholder="Expiry Date(MM/YY)"
                                        ccExp
                                        class="payment-input {{!paymentForm.controls.expDate.valid && paymentForm.controls.expDate.dirty ? 'invalid' : 'success'}}"
                                >
                            </div>
                            <div class="payment-input-group">
                                <input
                                        (input)="goToNextField('cvv', nameInput)"
                                        #cvcInput
                                        id="cc-cvc"
                                        type="password"
                                        formControlName="cvv"
                                        placeholder="CVV"
                                        ccCVC
                                        class="payment-input {{!paymentForm.controls.cvv.valid && paymentForm.controls.cvv.dirty ? 'invalid' : 'success'}}"
                                >
                            </div>
                        </div>
                            <div class="payment-input-group">
                                <input #nameInput formControlName="name_of_card" type="text" class="payment-input" placeholder="Enter card holder name">
                            </div>
                        <button [disabled]="!paymentForm.valid || pageLoader" class="menu-btn-primary heading-16 w-100">Proceed</button>
                    </form>


                </div>


            </div>

        </div>


    </div>

    <div class="menu-footere">
        <div class="footer-logo-sec">
            <p class="copyright-txt">Copyright @</p>
            <a href="#">
                <img src="../../assets/images/footer-logo.png" alt="foter-logo" class="footer-logo">
            </a>
        </div>

    </div>

</div>
