import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PaymentComponent} from "./payment.component";
import {ThanksComponent} from "./thanks.component";


const routes: Routes = [
  {
    path: 'payment/:id',
    component: PaymentComponent
  },
  {
    path: 'thanks',
    component: ThanksComponent
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentRoutingModule { }
