import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PaymentRoutingModule} from './payment-routing.module';
import {PaymentComponent} from './payment.component';
import {ThanksComponent} from "./thanks.component";
import {ReactiveFormsModule} from "@angular/forms";
import {CreditCardDirectivesModule} from "angular-cc-library";

@NgModule({
  declarations: [PaymentComponent, ThanksComponent],
  imports: [
    CommonModule,
    PaymentRoutingModule,
    ReactiveFormsModule,
    CreditCardDirectivesModule

  ],
  providers: []
})
export class PaymentModule { }
