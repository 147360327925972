<div class="flexbox payment-loader" *ngIf="pageLoader">
    <div>
        <div class="dbl-spinner"></div>
        <div class="dbl-spinner dbl-spinner--2"></div>
    </div>
</div>
<div class="resturant-page" *ngIf="orderDetails">
    <div class="menu-top-sec">
        <!-- <div class="header-menu-img">
                    <img src="../../assets/images/resturant-header-1.png" alt="" class="header-img">
        </div> -->
        <div class="menu-all-sec thanks-page">
            <!--<div class="error-sec">
                <img src="../../assets/images/error.png" alt="error" class="error-img">
                <p class="error-txt">Text related to error will appear here, Text related to error will appear here</p>
            </div>-->
            <div class="thanks-sec">
                <img src="../../assets/images/thanks.png" alt="thanks-img" class="thanks-img">
                <h4 class="heading-24">Thank you for ordering !</h4>
                <p class="heading-16">Your Order No is {{orderDetails.order_number}}, you can download the invoice. Sit back and relax, your order is being prepared. We will inform you when your order is ready</p>
                <a *ngIf="orderDetails.payment_type == 'Online'" href="{{orderDetails.invoice_url}}" target="_blank" class="menu-btn-primary heading-16 w-100">Download Invoice</a>
                <a [routerLink]="['/restaurant', restaurantDetails.token]" [queryParams]="params" class="heading-16 w-100 remove-text-decoration">Back to menu</a>
            </div>


        </div>
    </div>

    <div class="menu-footere">
        <div class="phone-no-sec">
            <span>Contact Details : </span>
            <ng-container *ngFor="let item of restaurantDetails.phone.split(',')">
                <a class="vikas-phone" href="tel:{{item}}">{{item}}</a>
            </ng-container>
        </div>
        <div class="footer-logo-sec">
            <p class="copyright-txt">Copyright @</p>
            <a href="#">
                <img alt="foter-logo" class="footer-logo" src="../../assets/images/footer-logo.png">
            </a>
        </div>
    </div>

</div>
