import {Component, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, UrlSerializer, UrlTree} from "@angular/router";

@Component({
    selector: 'app-restaurant',
    templateUrl: './restaurant.component.html',
    styleUrls: ['./restaurant.component.scss']
})
export class RestaurantComponent implements OnInit {

    restaurantId: any;
    data: any;
    items: any;
    searchText: any;
    menuItems: any;
    totalPrice: number = 0;
    cart: boolean = false;
    catSearch: boolean = false;
    readMore: any = {};
    largeImage: any = {};
    pageLoader: boolean = false;
    activeCat: any;
    cartItems: any;
    params: any;
    paramsTmp: any;
    totalItems: number = 0

    constructor(
        private httpClient: HttpClient,
        public router: Router,
        private activeRoute: ActivatedRoute,
        private serializer: UrlSerializer
    ) {
    }

    ngOnInit(): void {
        let restaurantId = this.activeRoute.snapshot.paramMap.get("id");
        let queryParams = this.activeRoute.snapshot.queryParams;
        this.paramsTmp = new HttpParams({ fromObject: queryParams });
        this.params = new HttpParams({ fromObject: queryParams }).toString();

        let fullUrl = localStorage.getItem('fullUrl');
        let cartItems = JSON.parse(localStorage.getItem('cart') || '{}');
        let currentUrl = window.location.href

        if (cartItems.length && currentUrl == fullUrl) {
            this.cartItems = cartItems;
            this.totalItems = this.cartItems.length

            this.cart = true
            let that = this;
            cartItems.forEach(function (item: any = []) {
                that.totalPrice = that.totalPrice + (item.item.price * item.qty);
            });
        }else{
            localStorage.clear();
        }
        localStorage.setItem('query_params', JSON.stringify(queryParams));
        this.restaurantId = restaurantId;
        localStorage.setItem('fullUrl', currentUrl);
        this.getRestaurantData();

    }

    getRestaurantData() {
        this.pageLoader = true;
        const invoiceURL = environment.API_ENDPOINT + 'restaurant/' + this.restaurantId + '?' + this.paramsTmp;
        this.httpClient.get(invoiceURL).subscribe(data => {
                if (data) {
                    this.data = data;
                    if(this.data.success) {
                        this.data = this.data.data;
                        // set cart item

                        if (this.cartItems && this.cartItems.length && this.data) {
                            let that = this;
                            this.cartItems.forEach(function (itemD: any) {
                                let index = that.data.items[itemD.item.category_name].findIndex((p: any) => p.id == itemD.item.id);
                                that.data.items[itemD.item.category_name][index].qty = itemD.qty;
                            });
                        }
                        localStorage.setItem('restaurantDetails', JSON.stringify(this.data.details));
                    }
                }
            },
            err => console.error(err),
            () => {

                this.pageLoader = false;

            }
        );
    }
    shortFoodType(foodTypes: string[]): string[] {
        let foodTypesArray: string[];
        if (typeof foodTypes === 'string') {
          foodTypesArray = (foodTypes as string).split(',');
        } else if (Array.isArray(foodTypes)) {
          foodTypesArray = [...foodTypes];
        } else {
          foodTypesArray = [];
        }
        if (foodTypesArray.length) {
          const indexVeg = foodTypesArray.indexOf('veg');
          if (indexVeg !== -1) {
            const vegItem = foodTypesArray.splice(indexVeg, 1)[0];
            foodTypesArray.unshift(vegItem);
          }
        }
        return foodTypesArray;
      }

    searchItem(data: any, type: any) {
        this.searchText = (type == 'text') ? data.value : data;
        let items;
        let res: any = [];
        if (this.searchText) {

            let that = this;
            let catItems = Object.values(this.data.items);
            if (type == 'text') {
                catItems.forEach(function (catItem: any) {
                    catItem.forEach(function (item: any) {
                        if (item.name.toLowerCase().indexOf(that.searchText.toLowerCase()) > -1) {
                            res.push(item)
                            that.catSearch = true
                        }
                    });
                });
            }
            if (type == 'cat') {
                this.activeCat = that.searchText;
                let catItems = Object.values(this.data.items);
                catItems.forEach(function (catItem: any) {
                    catItem.forEach(function (item: any) {
                        if (item.category_name.toLowerCase() === that.searchText.toLowerCase()) {
                            res.push(item)
                        }
                    });
                });
            }

            items = res;

        } else {
            items = null;
        }
        this.menuItems = items;
    }

    addToCart(item: any) {
        let items = JSON.parse(localStorage.getItem('cart') || '{}');
        let cartItems = [];
        let that = this;
        let product = {item: item, qty: 1};
        if (items.length) {
            if (!this.in_array(items, item.id)) {
                items.push(product);
                cartItems = items;
                that.totalPrice = that.totalPrice + item.price;
            }
        } else {
            cartItems.push(product)
            this.totalPrice = item.price;
        }
        if (cartItems.length) {
            // set cart into data
            let that = this;

            const dataItems= this.data.items[item.category_name];
            dataItems.forEach(function (itemD: any, i: number) {
                    if (itemD.id === item.id) {
                        that.data.items[item.category_name][i].qty = 1;
                    }
                });
            this.cart = true
            localStorage.setItem('cart', JSON.stringify(cartItems));
            this.totalItems = cartItems.length
        }
    }

    in_array(array: any, id: any) {
        let res = false;
        if (array.length) {
            for (var i = 0; i < array.length; i++) {
                if (array[i].item.id === id) {
                    res = true
                }
            }
            return res;

        }
        return false;
    }

    clearFilter() {
        this.searchText = null;
        this.activeCat = null
    }

    manageItem(item: any, type: boolean) {
        let cartItems = localStorage.getItem('cart');
        if (cartItems) {
            this.cartItems = JSON.parse(cartItems || '{}');
        }
        var index = this.cartItems.findIndex((p: any) => p.item.id == item.id);
        const dataItems= this.data.items[item.category_name];

        if (type) {
            let that = this;
           // let catItems = Object.values(this.data.items);
            dataItems.forEach(function (itemD: any, i: number) {
                    if (itemD.id === item.id) {
                        that.data.items[item.category_name][i].qty = that.data.items[item.category_name][i].qty + 1;
                    }
            });
            // increase cart qty in localhost
            this.cartItems[index].qty = this.cartItems[index].qty + 1;
            this.totalPrice = this.totalPrice + item.price;
        } else {
            let that = this;
            dataItems.forEach(function (itemD: any, i: number) {
                    if (itemD.id === item.id) {
                        that.data.items[item.category_name][i].qty = that.data.items[item.category_name][i].qty - 1;
                    }
            });
            // decrease cart qty in localhost
            this.cartItems[index].qty = this.cartItems[index].qty - 1;
            if (this.cartItems[index].qty == 0) {
                this.cartItems.splice(index, 1);
            }
            this.totalPrice = this.totalPrice - item.price;
            if (this.cartItems == 0) {
                this.cart = false
            }
        }
        if (this.cartItems) {
            localStorage.setItem('cart', JSON.stringify(this.cartItems));
        }
        this.totalItems = this.cartItems.length

    }


}
