import { Component, OnInit } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./payment.component.scss']
})
export class ThanksComponent implements OnInit {

  restaurantDetails: any;
  orderDetails: any;
  params: any;
  pageLoader: boolean = true;

    constructor(        private httpClient: HttpClient,
                      private router: Router,

  ) { }

  ngOnInit(): void {
    this.restaurantDetails = JSON.parse(localStorage.getItem('restaurantDetails') || '{}');
    this.params = JSON.parse(localStorage.getItem('query_params') || '{}');
    localStorage.removeItem("cart");
    this.getOrder(localStorage.getItem('order_id'))
  }

  getOrder(order_id: any){
    let orderUrl = environment.API_ENDPOINT + 'order/'+order_id;
    this.httpClient.get(orderUrl).subscribe(data => {
          this.orderDetails = data;
          this.orderDetails = this.orderDetails.details;
        },
        err => {
          // this.errorMsg = err.message;
        },
        () =>{
        this.pageLoader = false
          // complete
        }
    );
  }


}
