<div class="resturant-page">
    <div class="menu-top-sec">
        <div class="menu-all-sec summary-page">
            <div class="menu-listing-sec">
                <a [routerLink]="['/restaurant', restaurantDetails.token]" [queryParams]="params" class="back-btn">
                    <img src="../../assets/images/back-icon-1.png" class="back-icon" alt="back-icon">
                </a>
                <h4 class="menu-top-heading">Order summary</h4>
                <div class="summary-total-item-sec">
                    <p class="summary-total-item">{{totalItems}} {{(totalItems > 1)?'ITEMS': 'ITEM'}}</p>
                    <!--<button class="edit-cart">Edit cart</button>-->
                </div>
                <div class="menu-inner-list" *ngIf="data.length">
                    <div class="menu-list-item" *ngFor="let item of data let i = index">

                    <div class="menu-contant-top flexdv">
                        <div class="restaurantName">
                        <div class="menu-green-dot" *ngIf="item.item.is_veg"></div>
                        <div class="menu-red-dot" *ngIf="!item.item.is_veg"></div>
                        <h4 class="heading-16">{{item.item.name}}</h4>
                        </div>

                     <div class="menu-item-count-sec">
                        <button class="sub-btn" (click)="manageItem(item.item, false, i)">
                            <span class="sub-icon-line"></span>
                        </button>
                        <input type="text" class="menu-item-count-input" readonly value="{{item.qty}}">
                        <button class="plus-btn" (click)="manageItem(item.item, true, i)">
                            <span class="add-icon-line-1"></span>
                            <span class="add-icon-line-2"></span>
                        </button>
                    </div>
                </div>
                   <p class="menu-amount normal-txt ml24 mb5">₹ {{item.item.price * item.qty}}</p>
                       
                    </div>
                </div>

            </div>
            <div class="summary-detail">
                <div class="amount-summary-sec"  *ngIf="!restaurantDetails.meta.is_hide_grand_total">
                    <div class="amount-summary-item">
                        <p class="amount-subtotal-label">Subtotal</p>
                        <p class="amount-subtotal-label">₹ {{totalPrice}}.00</p>
                    </div>
                    <div class="amount-summary-item">
                        <p class="amount-subtotal-label f-w-n">Taxes</p>
                        <p class="amount-subtotal-label f-w-n">₹ {{tax}}</p>
                    </div>
                    <div class="amount-summary-item amount-summary-total">
                        <p class="amount-subtotal-label">Grand total</p>
                        <p class="amount-subtotal-label">₹ {{totalPrice + tax}}</p>
                    </div>
                </div>
                <div class="summary-total-btn">
                    <button (click)="placeOrder()" [disabled]="loader" class="menu-btn-primary heading-16 w-100 place-order-btn">Place order<span *ngIf="loader" class="loader"></span></button>
                </div>
            </div>

        </div>
    </div>

    <div class="menu-footere">
        <div class="phone-no-sec">
            <span>Contact Details : </span>
            <ng-container *ngFor="let item of restaurantDetails.phone.split(',')">
                <a class="vikas-phone" href="tel:{{item}}">{{item}}</a>
            </ng-container>
        </div>
        <div class="footer-logo-sec">
            <p class="copyright-txt">Copyright @</p>
            <a href="#">
                <img alt="foter-logo" class="footer-logo" src="../../assets/images/footer-logo.png">
            </a>
        </div>
    </div>
    <div class="menu-cat-modal modal-contact" [ngClass]="contactModel ? 'open-modal' : ''"> <!--open-modal-->
        <div class="menu-modal-contant">
            <div class="contact-detail-top">
                <h4 class="heading-24">Confirm your email</h4>
                <a class="close-modal" (click)="contactModel=false">
                    <img src="../../assets/images/modal-close.png" alt="close-icon" class="close-btn-close">
                </a>
            </div>
            <p class="heading-16 f-w-n">Please provide the following details to place your order</p>
            <form class="payment-form" [formGroup]="customerForm">
                <ng-container *ngIf="restaurantDetails.meta.is_staying">
                    <div class="payment-input-group">
                        <span>Are you staying?</span>
                        <input type="radio"  value="1" class="staying" (click)="roomStayingF(true)" formControlName="staying">Yes
                        <input type="radio"  value="0" class="staying" (click)="roomStayingF(false)" formControlName="staying">No
                    </div>
                    <div class="payment-input-group" *ngIf="roomStaying">
                        <input type="text" class="payment-input" formControlName="room_no" placeholder="Room No">
                    </div>
                </ng-container>
                <div class="payment-input-group" *ngIf="restaurantDetails.meta.is_table_number">
                    <input type="text" class="payment-input" formControlName="table_no" placeholder="Table No">
                </div>

                <div class="payment-input-group" *ngIf="restaurantDetails.meta.is_stw">
                    <input type="text" class="payment-input" formControlName="stw" placeholder="STW">
                </div>

                <div class="payment-input-group" *ngIf="restaurantDetails.meta.is_cvr">
                    <input type="text" class="payment-input" formControlName="cvr" placeholder="Cover">
                </div>

                <div class="payment-input-group">
                    <input type="text" class="payment-input" formControlName="name" placeholder="Customer name">
                </div>
                <div class="payment-input-group">
                    <input  type="email" [email]="true" class="payment-input {{!customerForm.controls.email.valid && customerForm.controls.email.dirty ? 'invalid' : 'success'}}" formControlName="email" placeholder="Customer Email">
                </div>
                <div class="payment-input-group whatsapp">
                    <input type="number" class="payment-input {{!customerForm.controls.phone.valid && customerForm.controls.phone.dirty ? 'invalid' : 'success'}}" formControlName="phone" placeholder="Contact number">

                </div>

                    <button [disabled]="!customerForm.valid || loader" class="menu-btn-primary heading-16 w-100" (click)="manageUserVerification()">Continue <span *ngIf="loader" class="loader"></span></button>
                    <div class="error-sec" *ngIf="errorMsg">
                        <img src="../../assets/images/error.png" alt="error" class="error-img">
                        <p class="error-txt">{{errorMsg}}</p>
                    </div>
            </form>

        </div>
    </div>
    <div  class="menu-cat-modal modal-contact" [ngClass]="OTPModel ? 'open-modal' : ''"> <!--open-modal-->
        <div class="menu-modal-contant">
            <div class="contact-detail-top">
                <h4 class="heading-24">Enter OTP</h4>

            </div>
            <p class="otpSubheading">Please enter the OTP sent on email address </p>
                <div class="payment-input-group">
                    <input type="text" class="payment-input" (keyup)="setOtp($event.target)"  placeholder="Enter OTP">
                </div>
                <button [disabled]="!OTP || loader" class="menu-btn-primary heading-16 w-100 otpBtn" (click)="verifyOTP()">Validate OTP <span *ngIf="loader" class="loader"></span></button>
            <div class="error-sec" *ngIf="otpError">
                <img src="../../assets/images/error.png" alt="error" class="error-img">
                <p class="error-txt">{{otpError}}</p>
            </div>
        </div>
    </div>

</div>
