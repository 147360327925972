import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {OrderModule} from "./order/order.module";
import {PaymentModule} from "./payment/payment.module";
import {RestaurantModule} from "./restaurant/restaurant.module";
import { VarDirective } from './var.directive';

@NgModule({
  declarations: [
    AppComponent,
    VarDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RestaurantModule,
    OrderModule,
    PaymentModule,
  ],
  providers: [],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
